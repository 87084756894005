import { ViewportRuler } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
// Angular Universal requires HttpClientModule to be declared at app level
// https://www.thecodecampus.de/blog/angular-universal-xmlhttprequest-not-defined-httpclient/
import { HttpClientModule, HttpClientXsrfModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Inject, inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { InMemoryCache } from '@apollo/client/core';
import * as Directives from '@app/etfs-equities/directives';
import { environment } from '@env/environment';
import { EtfsEquitiesModule } from '@etfs-equities/etfs-equities.module';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from '@shared/shared.module';
import {
  AngularOpenTelemetryService,
  COLLECTOR_ENVIRONMENT,
  COLLECTOR_LOCATION,
} from '@vanguard/invest-otel-lib/angular';
import {
  ConsoleErrorReporterModule,
  ErrorHandlerModule,
  OpentelemetryErrorReporterModule,
} from '@vanguard/trade-error-handler-lib';
import { LinkComponent } from '@vg-constellation/angular-17/link';
import { ModalDialogModule } from '@vg-constellation/angular-17/modal-dialog';
import { SpinnerComponent } from '@vg-constellation/angular-17/spinner';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { createPersistedQueryLink } from 'apollo-angular/persisted-queries';
import { sha256 } from 'crypto-hash';
import { CookieService } from 'ngx-cookie-service';
import { catchError, firstValueFrom, map, of, tap } from 'rxjs';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { httpInterceptorProviders } from './core/interceptors';
import { loadingInterceptor } from './core/interceptors/loading-interceptor';
import { GatekeeperService, LoggerService, MetaDataService } from './core/services';
import { SessionCheckService } from './core/services/session-check/session-check.service';
import { OpentelemetryModule } from './core/tracing/opentelemetry.module';
import { CONSTANTS } from './etfs-equities/constants';
import { WindowService } from './etfs-equities/services';
import { EnvironmentService } from './shared/services/environment/environment.service';

function initializeApp() {
  const metaDataService = inject(MetaDataService);
  const metaService = inject(Meta);
  const windowService = inject(WindowService);
  const environmentService = inject(EnvironmentService);
  const sessionService = inject(SessionCheckService);
  const gatekeeperService = inject(GatekeeperService);

  return async () => {
    const queryToken = windowService.getSearch().get('token');
    const isServerless = environmentService.isServerlessEnvironment();
    const redirectUrl = queryToken ? window.location.href : environment.buySellRouterUrl;
    const logonUrlRedirect = environment.logonURL + '&TARGET_OVERRIDE=' + encodeURI(redirectUrl);
    let sessionValid = false;

    // when NOT serverless and NOT localhost check session and return
    if (!isServerless && !sessionService.isLocalhost) {
      sessionValid = await firstValueFrom(
        sessionService.checkSession(queryToken).pipe(
          map(() => true),
          catchError(() => of(false))
        )
      );

      if (!sessionValid) {
        windowService.navigateToExternalLink(logonUrlRedirect);
      }

      return Promise.resolve();
    }

    if (isServerless) {
      // add path for serverless assets
      windowService.assetsPath = windowService.getServerlessPath();
    }
    await environmentService.setIsInactive();

    return firstValueFrom(
      metaDataService.getMetaData(queryToken).pipe(
        tap((metaData) => {
          if (metaData !== null) {
            // init gatekeeper feature status
            gatekeeperService.initializeFeatureStatus(metaData.multiToggleResponse);

            // set meta tags
            Object.keys(metaData.metaTags).forEach((key) => {
              metaService.updateTag({ name: key, content: metaData.metaTags[key] });
            });
          } else {
            if (!sessionService.isLocalhost) {
              const url = sessionService.sessionFailed ? logonUrlRedirect : environment.buySellRouterUrl;
              windowService.navigateToExternalLink(url);
            }
          }
        })
      )
    );
  };
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    // General imports...
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    HttpClientModule,
    SharedModule,
    // NgRx imports...
    StoreModule.forRoot({ router: routerReducer }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    EtfsEquitiesModule,
    ModalDialogModule.forRoot(),
    OpentelemetryModule,
    HttpClientXsrfModule.withOptions({
      cookieName: CONSTANTS.XSRF_TOKEN,
      headerName: CONSTANTS.TWE_XSRF_HEADER_NAME,
    }),
    ErrorHandlerModule,
    OpentelemetryErrorReporterModule,
    ConsoleErrorReporterModule,
    SpinnerComponent,
    LinkComponent,
    ApolloModule,
  ],
  declarations: [AppComponent, Directives.LoadScriptDirective],
  providers: [
    {
      provide: COLLECTOR_ENVIRONMENT,
      useValue: environment.otelCollectorEnvironment,
    },
    {
      provide: COLLECTOR_LOCATION,
      useValue: environment.otelCollectorLocation,
    },
    {
      provide: APP_ID,
      useValue: 'trade-web-angular',
    },
    ViewportRuler,
    provideHttpClient(withInterceptors([loadingInterceptor])),
    httpInterceptorProviders,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [
        MetaDataService,
        Meta,
        WindowService,
        EnvironmentService,
        SessionCheckService,
        GatekeeperService,
        LoggerService,
        AngularOpenTelemetryService,
      ],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: createPersistedQueryLink({
            sha256,
          }).concat(
            httpLink.create({
              //endpoint will need to be updated to use a more generic query so we can get all data instead of just announcmentBanner data
              uri: `${environment.graphQLDomain}/trd-etfs-equities-options/announcementBanner`,
              method: 'GET',
            })
          ),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) platformId: Record<string, unknown>, @Inject(APP_ID) appId: string) {
    const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
